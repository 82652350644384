let serviceName =  'Datacord Website Microservice'; 

export function logs(...args: any[]) {
  console.log(`[${serviceName}]` + ` - `+`[${new Date().toUTCString()}]`, ...args);
}

export function errors(...args: any[]) {
  console.error(`[${serviceName}]` + ` - `+`[${new Date().toUTCString()}]`, ...args);
}

export function changeServiceName(name: string) {
  serviceName = name;
}